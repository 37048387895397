.productImg {
  object-fit: contain;
}

.variantContainer {
  position: relative;
  min-height: 200px !important;
}

.boxItem:hover .aRIcon {
  transform: rotate(360deg);
}

.boxItem:not(:hover) .aRIcon{
  transition: transform 0s;
}

.variant, .variantExt {
  width: 100%;
  height: 100%;
}

.variantExt {
  -webkit-filter: drop-shadow(0px 0px 6px rgba(1, 1, 1, .8));
  filter: drop-shadow(0px 0px 6px rgba(1, 1, 1, .8));
}

.boxItem {
  padding-top: 0 !important;
  height: 100%;
  aspect-ratio: 1/1;
  cursor: pointer;
  min-height: 200px !important;
}
.itemButtonBase {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  border-radius: 10px;
  background-size: cover;
  cursor: pointer;
}
.aRIconContainer {
  position: absolute;
  right: 0;
  top: 0;
  margin: 10px 10px 0 0;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
  border-radius: 20px;
  overflow: hidden;
  width: 40px;
  height: 40px;
}
.aRIcon {
  width: 40px;
  height: 40px;
  transition: transform 0.4s ease-in;
}

.navButtonsDetails {
  background: none !important;
  color: #c3c3c3 !important;
  width: 45px !important;
  height: 45px !important;
}

.navButtons {
  background: none !important;
  color: #c3c3c3 !important;
  width: 45px !important;
  height: 45px !important;
}

.navButtons svg {
  width: 100%;
  height: 100%;
}

.navButtonsDetails svg {
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 50%;
  border: 1px solid #C5C5C5;
}

.indicatorContainer {
  position: absolute !important;
  bottom: 10px !important;
  z-index: 1000 !important;
}

.indicatorContainer button {
  margin: 1px;
  width: 23px !important;
  height: 23px !important;
}

.indicatorContainer button svg {
  width: 100%;
  height: 100%;
}

.indicatorIconDetails {
  background: #EEEEEE !important
}

.indicatorIcon {
  color: #EEEEEE !important
}

.activeIndicatorIconDetails {
  background: #C5C5C5 !important
}

.activeIndicatorIcon {
  color: #C5C5C5 !important
}

.exindicatorIconDetails {
  margin-left: 4px !important;
  margin-right: 4px !important;
  color: transparent !important;
  background: transparent !important;
  border: 3px solid white !important;
  box-shadow: 2px 2px 3px 2px rgba(1, 1, 1, .4);
}
.exactiveIndicatorIconDetails {
  margin-left: 4px !important;
  margin-right: 4px !important;
  color: white !important;
  background: white !important;
  border: none !important;
  box-shadow: 2px 2px 3px 2px rgba(1, 1, 1, .4);
}

.soldOut {
  position: absolute;
  top: 0;
  left: 0;
  background: #c09de1;
  padding: 8px;
  padding-left: 12px;
  padding-right: 12px;
  border-bottom-right-radius: 10px;
  color: white
}
