.centered {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: auto;
}

@media (max-height: 700px) {
  .storeHeaderContainer {
    height: 150px !important;
  }
}

.storeProductsContainer {
  padding-top: 20px;
  padding-bottom: 20px;
}

.loadMoreButton {
  width: 200px;
  height: 60px;
  align-self: center;
}
.divider {
  width: 100%;
  height: 1px;
  background-color: #e5e7eb;
}
.footer {
  width: 100%;
  display:flex;
  flex-direction: row;
  justify-content: center;
  gap: 5px;
  margin-top: 40px;
  margin-bottom: 10px
}