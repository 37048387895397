.productContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  min-height: 100vh;
  /* Update: changed from 99vh to 100vh */
}

.gridContainer {
  justify-content: center;
  align-items: center;
  padding-top: 0;
}

.rowDescriptionAR {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  font-weight: 500;
  font-size: 1.5rem;
}

.aRIcon {
  width: 40px;
  height: 40px;
  float: right;
  border-radius: 20px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07),
    0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07),
    0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);
  margin-left: 40px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07),
    0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07),
    0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);
  /* Added for Safari */
}

.yugeLink {
  color: blue;
  margin-left: 5px;
  text-decoration: none;
}

.rowPowered {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 100px;
}

.qrContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.qrContainer img {
  width: 50%;
}

.gradient {
  background: linear-gradient(130deg, #05d9f1, #8fb9f8, #a1c4ff, #9bc4ff, #beccea, #c8c8c8, #dddddd);
  background-size: 400% 400%;
  -webkit-animation: Animation 15s ease infinite;
  -moz-animation: Animation 15s ease infinite;
  animation: Animation 15s ease infinite;
}

@-webkit-keyframes Animation {
  0% {
    background-position: 10% 0%
  }

  50% {
    background-position: 91% 100%
  }

  100% {
    background-position: 10% 0%
  }
}

@-moz-keyframes Animation {
  0% {
    background-position: 10% 0%
  }

  50% {
    background-position: 91% 100%
  }

  100% {
    background-position: 10% 0%
  }
}

@keyframes Animation {
  0% {
    background-position: 10% 0%
  }

  50% {
    background-position: 91% 100%
  }

  100% {
    background-position: 10% 0%
  }
}

.centerMessage {
  min-height: 100vh;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.centerMessage a {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #4d4d4d;
  text-decoration: none;
}

.centerMessage a:hover {
  text-decoration: underline;
}

.productContainer2 {
  width: 100%;
  min-height: 100vh;
  background-color: #f8f9fa;
  margin-bottom: -21px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 5rem;
  padding-bottom: 3.5rem;
}

.qrContainer2 {
  box-sizing: border-box;
  background-color: white;
  min-width: 405px;
  min-height: 600px;
  background-color: white;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-top: 3rem;
  padding-bottom: 2.8rem;
}

.rowPowered2 {
  margin-top: 30px;

}

.yugeLink2 {
  width: 100px;
  margin-left: 0.25rem;
}

.variantContainer {
  display: flex;
  min-height: 200px !important;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.variantExt {
  -webkit-filter: drop-shadow(0px 0px 6px rgba(1, 1, 1, .8));
  filter: drop-shadow(0px 0px 6px rgba(1, 1, 1, .8));
}
