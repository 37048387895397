.container {
  height: 100%;
  z-index: 3;
}
.bgImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
  float: left;
}
.interior {
  width: 100%;
  height: 100%;
  position: relative;
}

.interiorContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
}

.title {
  margin-top: 0;
  text-shadow: 0 0 15px #000;
  color: white;
  font-size: 1.5rem;
}

.subtitle {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-shadow: 0 0 15px #000;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.storeData {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.workingTime {
  background-color: #fef2f2;
  border-radius: 5px;
  padding: 5px;
  font-size: 0.75rem;
}
