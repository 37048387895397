.appBar {
  min-height: 105px;
  padding: 5px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.backContainer {
  display: flex;
  align-items: center;
  flex-direction: row; 
  padding-left: 8px;
  padding-top: 20px;
}
.logo {
  max-height: 70px;
}
.languageSelector {
  width: 9rem;
  height: 45px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  padding-left: 10px;
  border-radius: 5px;
}
.flag {
  width: 45px;
  height: 20px;
}
.icon {
  height: 25px;
  width: 22px;
}
.title {
  font-size: 22px;
  color: #fbbf24;
}
.back {
  margin-right: 10px;
  margin-top: 5px;
}
.divider {
  width: 100%;
  height: 1px;
  background-color: #e5e7eb;
  margin-top: 25px;
}

@media (min-width: 1580px) {
  .backContainer {
    padding-left: 28px;
  }
}